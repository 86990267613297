/* You can add global styles to this file, and also import other style files */
@import '~@aws-amplify/ui-angular/theme.css';
@import './_ts-breakpoints.scss';

// ----------------------------------------------------------------------------
// # CSS CUSTOM PROPERTIES
// ----------------------------------------------------------------------------

:root {
  // Colors
  --ink-100: hsl(357, 68%, 18%);
  --ink-50: hsl(358, 16%, 40%);
  --ink-30: hsl(354, 8%, 75%);

  --orange-100: hsl(31, 81%, 21%);
  --orange-90: hsl(31, 81%, 28%);
  --orange-80: hsl(31, 81%, 33%);
  --orange-70: hsl(31, 81%, 39%);
  --orange-60: hsl(31, 81%, 43%);
  --orange-50: hsl(31, 81%, 52%);
  --orange-40: hsl(31, 61%, 66%);
  --orange-30: hsl(31, 61%, 73%);
  --orange-25: hsl(31, 61%, 78%);
  --orange-20: hsl(31, 61%, 85%);
  --orange-15: hsl(31, 61%, 90%);
  --orange-10: hsl(31, 61%, 95%);

  --blue-100: hsl(200, 65%, 20%);
  --blue-90: hsl(200, 65%, 25%);
  --blue-80: hsl(200, 65%, 30%);
  --blue-70: hsl(200, 65%, 35%);
  --blue-60: hsl(200, 65%, 40%);
  --blue-50: hsl(200, 65%, 45%);
  --blue-40: hsl(200, 55%, 66%);
  --blue-30: hsl(200, 55%, 73%);
  --blue-25: hsl(200, 55%, 78%);
  --blue-20: hsl(200, 55%, 85%);
  --blue-15: hsl(200, 55%, 90%);
  --blue-10: hsl(200, 55%, 95%);

  --green-100: hsl(151, 68%, 12%);
  --green-90: hsl(151, 68%, 14%);
  --green-80: hsl(151, 68%, 16%);
  --green-70: hsl(151, 78%, 18%);
  --green-60: hsl(151, 88%, 20%);
  --green-50: hsl(151, 98%, 22%);
  --green-40: hsl(151, 78%, 33%);
  --green-30: hsl(151, 54%, 44%);
  --green-25: hsl(151, 40%, 62%);
  --green-20: hsl(151, 40%, 74%);
  --green-15: hsl(151, 40%, 84%);
  --green-10: hsl(151, 50%, 91%);

  --red-100: hsl(357, 68%, 21%);
  --red-90: hsl(357, 68%, 25%);
  --red-80: hsl(357, 68%, 30%);
  --red-70: hsl(357, 68%, 36%);
  --red-60: hsl(357, 68%, 41%);
  --red-50: hsl(357, 68%, 46%);
  --red-40: hsl(357, 58%, 61%);
  --red-30: hsl(357, 58%, 69%);
  --red-25: hsl(357, 58%, 77%);
  --red-20: hsl(357, 58%, 85%);
  --red-15: hsl(357, 58%, 92%);
  --red-10: hsl(357, 68%, 97%);

  --neutral-110: hsl(200, 2%, 9%);
  --neutral-100: hsl(200, 2%, 21%);
  --neutral-90: hsl(200, 2%, 30%);
  --neutral-80: hsl(200, 2%, 35%);
  --neutral-70: hsl(200, 2%, 40%);
  --neutral-60: hsl(200, 2%, 46%);
  --neutral-50: hsl(200, 2%, 50%);
  --neutral-40: hsl(200, 2%, 66%);
  --neutral-30: hsl(200, 2%, 73%);
  --neutral-25: hsl(200, 2%, 78%);
  --neutral-20: hsl(200, 2%, 85%);
  --neutral-15: hsl(200, 2%, 92%);
  --neutral-10: hsl(200, 7%, 97%);
  --white: #ffffff;

  // Bubbles
  --bubble-color: 0, 0, 0;
  --bubble-opacity: 0.2;

  // Forms
  --input-color: var(--blue-50);
  --input-color-disabled: var(--ink-100);

  // Body font size
  --font-size-base: 1.1rem;

  // Type scaling
  --text-scale-ratio: 1.33;
  --text-xxs: calc(var(--font-size-base) / var(--text-scale-ratio));
  --text-xs: calc(var(--font-size-base) / (var(--text-scale-ratio) * 0.875));
  --text-sm: var(--font-size-base);
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));

  // Modules and navigation
  --module-here: var(--orange-70);
  --module-social-media: var(--neutral-90);
  --module-experience: var(--blue-70);
  --module-explore: var(--green-70);
  --module-together: var(--red-70);

  // Masonry
  --img-brightness: 80%;

  // Misc
  --border-radius-base: 3px;

  // z-index hiearchy
  --z-loading: 100;
  --z-toast: 90;
  --z-modal: 80;
  --z-header: 70;
  --z-menu: 60;
  --z-main: 50;

  // ----------------------------------------------------------------------------
  // Elevations
  // ----------------------------------------------------------------------------
  --ts-elevation-01-color: 0, 0, 0;
  --ts-elevation-01: 0 2px 6px 0 rgba(var(--ts-elevation-01-color), 0.16);

  --ts-elevation-02-color: 0, 0, 0;
  --ts-elevation-02: 0 3px 10px 0 rgba(var(--ts-elevation-02-color), 0.26);

  --ts-elevation-03-color: 0, 0, 0;
  --ts-elevation-03: 0 5px 16px 0 rgba(var(--ts-elevation-03-color), 0.46);

  // Body font size
  --font-size-base: 1rem;

  // Type scaling
  --text-scale-ratio: 1.33;
  --text-xxs: calc(var(--font-size-base) / var(--text-scale-ratio));
  --text-xs: calc(var(--font-size-base) / (var(--text-scale-ratio) * 0.875));
  --text-sm: var(--font-size-base);
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
}

html,
body {
  min-height: 100vh;
  height: auto;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background-color: var(--neutral-80);
  color: #fff;
  font-family: "Ubuntu", sans-serif;
  font-size: var(--font-size-base);
  font-weight: 300;
  margin: 0;
  position: relative;
}

.mat-app-background {
  background-color: var(--neutral-80);
  color: #fff;
}


// Buttons
.mdc-button--raised {

  &.ts-btn-selected {
    background: var(--neutral-100) !important;
    color: #fff !important;
    font-weight: normal !important;
  }

  &:disabled {
    background-color: var(--neutral-30) !important;
    color: var(--neutral-90) !important;
  }

  ;
}

.ts-app-flow {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  padding-top: 2rem;

  mat-raised-button {
    padding: 0.5em;
  }

  mat-icon {
    transform: rotate(90deg);
  }

  // 576px window width and more
  @include bp-sm {
    flex-direction: row;

    mat-icon {
      transform: rotate(0deg);
    }
  }
}

// Sign-in
[data-amplify-authenticator-signin],
[data-amplify-authenticator-resetpassword] {
  box-shadow: var(--ts-elevation-03);

  .amplify-button[data-variation='link'] {
    color: var(--green-50);

    &:hover {
      background: none;
      text-decoration: underline;
      color: var(--green-70);
    }
  }

  .amplify-button[data-variation='primary'] {
    background-color: var(--green-50);
    color: #ffffff;

    &:hover {
      background-color: var(--green-70);
      color: #fff;
      text-decoration: none;
    }
  }
}

// Sign-out
amplify-authenticator {
  .signedIn {
    color: var(--ink-100);
    box-shadow: var(--ts-elevation-03);

    p {
      margin: 0;

      &.bold {
        font-weight: 700;
        margin-bottom: 2rem;
      }
    }

    .amplify-button,
    .amplify-button[data-variation='primary'] {
      background-color: var(--green-50);
      color: #ffffff;

      &:hover {
        background-color: var(--green-80);
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

// ----------------------------------------------------------------------------
// Flexbox
// ----------------------------------------------------------------------------
.ts-container {
  max-width: 45rem;
  //Padding skapar problem med videos i why komponenten.
  padding: min(10vw, 2rem);

  margin: 0 auto 3rem auto;

  .ts-flex {
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;
    gap: 32px;

    &.ts-flex--column {
      align-items: center;
      flex-direction: column;
      gap: 40px;
    }
  }
}

// ----------------------------------------------------------------------------
// Typography
// ----------------------------------------------------------------------------

a {
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--neutral-30);
  font-weight: 500;
  line-height: 1;
  margin: 0 0 1.5rem 0;
}

h2,
.h2 {
  font-size: 2rem;

  // 576px window width and more
  @include bp-sm {
    font-size: 2.4rem;
    letter-spacing: -1.1px;
  }
}

h3,
.h3 {
  font-size: 1.8rem;
  font-weight: 400;

  // 576px window width and more
  @include bp-sm {
    font-size: 2rem;
    letter-spacing: -1px;
  }
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: var(--neutral-30);

  font-size: 1.4rem;
  letter-spacing: 0;
  margin: 0 0 0.6rem 0;

  // 576px window width and more
  @include bp-sm {
    font-size: 1.8rem;
  }
}

// Typography
.ts-text-xs {
  font-size: var(--text-xs);
}

.ts-text-sm {
  font-size: var(--text-sm);
}

.ts-text-md {
  font-size: var(--text-md);
}

.ts-text-lg {
  font-size: var(--text-lg);
}

.ts-text-xl {
  font-size: var(--text-xl);
}

.ts-text-xxl {
  font-size: var(--text-xxl);
}

.ts-text-xxxl {
  font-size: var(--text-xxxl);
}

// ----------------------------------------------------------------------------
// Misc
// ----------------------------------------------------------------------------

hr {
  height: 1px;
  border: 0;
  border-bottom: 1px solid var(--neutral-60);
  margin: 2rem 0;
}

.version-number {
  background-color: rgba(255, 255, 255, .5);
  border-radius: 4px;
  bottom: 16px;
  color: #000;
  font-size: .7rem;
  opacity: .5;
  padding: 0 6px;
  position: fixed;
  right: 16px;
  text-transform: uppercase;
  text-align: right;
  z-index: 9999;
}
